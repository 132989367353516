/**
 * @type { Copyright: { Company: string; Year: number; Author: string }; Social: ({ name: string; url: string } | { name: string; url: string } | { name: string; url: string } | { name: string; url: string } | { name: string; url: string })[]; Description: { Meta: string; Hero: string }; Title: { Default: string; Main: string; Short: string }; BaseUrl: string; Icon: { Default: string }; Tags: { Default: string[] } }
 */
const AppRegistry = {
    BaseUrl: "https://insights.quant.one",
    Title: {
        Default: 'Executive Insights on Enabling #DigitalTransformation Success',
        Short: 'Executive #DigitalTransformation Insights',
        Main: 'Quant ONE Insights'
    },
    Description: {
        Meta: 'Get actionable insights every CxO must know for enabling #Digital Transformation success in your firm. Data-Driven, Cloud-First, and Customer-Centric',
        Hero: 'Explore insights & publications to learn how we can empower your team with digital capabilities for proven results.',
        ArticleLead: 'Let us empower your team with a #DigitalTransformation strategy session covering impactful Cloud + AI solutions.'
    },
    Copyright: {
        Company: 'Quant ONE Inc.',
        Author: 'Sar Malik',
        Year: 2022
    },
    Contact: {
        Email: {
            Raw: 'consulting@quant.one',
            Parameters: 'mailto:consulting@quant.one?subject=Digital%20Transformation'
        },
        Twitter: {
            Company: '@quantoneinc',
            Author: '@tcpnet'
        }
    },
    Social: [
        {
            name: `twitter`,
            url: `https://twitter.com/QuantONEInc`,
        },
        {
            name: `linkedin`,
            url: `https://www.linkedin.com/company/quantone/`,
        },
        {
            name: `dribbble`,
            url: `https://dribbble.com/QuantONE`,
        },
        {
            name: `instagram`,
            url: `https://instagram.com/QuantONEInc`,
        },
        {
            name: `github`,
            url: `https://github.com/DigitalTransformation`,
        },
    ],
    Icon: {
        "Default": "src/assets/quant.one.icon.png"
    },
    Author: {
        Default: 'Sar Malik'
    },
    Tags: {
        Header: 'We Are',
        Default: [
            'Digital Transformation',
            'Business',
            'Management',
            'Leadership',
            'Insights',
            'App Dev',
            'Cloud',
            'Azure',
            'AWS',
            'GCP'
        ],
        Compose: [
            'Digital Architects',
            'Designers',
            'Entrepreneurs',
            'Innovators',
            'Digital Marketers',
            'App Developers',
            'Strategic Thinkers',
            'Investors',
            'Quant ONE'
        ]
    },
    Tokens: {
        WebTrust: '7cfa65961bed2e80b506',
        SafeWeb: 'kxcr4kntn5t2mywct3o9kts9j7v4j7705rnlhbv4p3zcqp-jd25bwvyxiired792n7rwknx7sivpply7zsgjuybgs5z4br1t4jtqzmq8o5ykhkffw86fmffwq8bd0-a6',
        GoogleTags: [
            'UA-89641143-2'
        ],
    },
    Policy: {
        Directives: {
            "default-src": "'none'" ,
            "connect-src": "'self' https://www.cloudflare.com/cdn-cgi/trace",
            "font-src": "'self' https://fonts.gstatic.com",
            "frame-src": "img-src 'self' data: https://www.google-analytics.com",
            "script-src": "'self' 'unsafe-eval' 'unsafe-inline' https://www.google-analytics.com/analytics.js https://www.googletagmanager.com/gtag/js",
            "style-src": "'unsafe-inline' https://fonts.googleapis.com/"
        }
    }
};

export default AppRegistry;
