import React, { useEffect } from 'react';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import { useColorMode } from 'theme-ui';

import NavigationFooter from '../Navigation/Navigation.Footer'
import ArticlesContextProvider from '../../sections/articles/Articles.List.Context';

import { globalStyles } from '@styles';
// import {BrowserAnalytics} from '@digitaltransformation/qone.saas.analytics';

/**
 * <Layout /> needs to wrap every page as it provides styles, navigation,
 * and the main structure of each page. Within Layout we have the <Container />
 * which hides a lot of the mess we need to create our Desktop and Mobile experiences.
 */
const Layout: React.FC<{}> = ({ children }) => {
  const [colorMode] = useColorMode();

  useEffect(() => {
    parent.postMessage({theme: colorMode}, '*');
  }, [colorMode]);

  // BUG: The above error occurred in the <LocationProvider> component:
    // in LocationProvider (created by Context.Consumer)
    // in Location (at root.js:125)
    // in Root (at root.js:133)
    // in MDXProvider (created by BaseProvider)
    // in BaseProvider (created by RootProvider)
    // in RootProvider (created by ThemeProvider)
    // in ThemeProvider
    // in ThemeStateProvider
    // in MDXProvider (at wrap-root-element.js:65)
    // in MDXScopeProvider (at wrap-root-element.js:64)
    // in Unknown
    // in Unknown (at wrap-root-element.js:72)
    // in StaticQueryStore (at root.js:150)
    // in ConditionalFastRefreshOverlay (at root.js:149)
    // in _default (at app.js:163)
  // useEffect(() => {
  //    BrowserAnalytics
  //        .FingerprintStateAsync()
  //        .then(r => null);
  // });

  return (
      <ArticlesContextProvider>
        <Container>
          <Global styles={globalStyles}/>
          {children}
          <NavigationFooter/>
        </Container>
      </ArticlesContextProvider>
  );
}

export default Layout;

const Container = styled.div`
  position: relative;
  background: ${p => p.theme.colors.background};
  transition: ${p => p.theme.colorModeTransition};
  min-height: 100vh;
  overflow-x: hidden;
`;
