import React from "react";
import styled from "@emotion/styled";
import {graphql, Link, StaticQuery} from "gatsby";
import {useColorMode} from "theme-ui";

import Section from "@components/Section";
import Headings from '@narative/gatsby-theme-novela/src/components/Headings';
import {Flex, Box} from '@rebass/grid';
import {AWSCoMarketing, Logo as DefaultLogo} from '@digitaltransformation/qone.saas.branding';
import {UserCheck} from "@styled-icons/feather";
import {MainCover} from "@digitaltransformation/qone.pages.layout";
import AppRegistry from "../../../../meta/AppRegistry";

const siteQuery = graphql`
    query {
        allSite {
            edges {
                node {
                    siteMetadata {
                        name
                    }
                }
            }
        }

        sitePlugin(name: {eq: "@narative/gatsby-theme-novela"}) {
            pluginOptions {
                rootPath
                basePath
            }
        }

        imageSharp(fluid: { originalName: {eq: "quantone-cover-footer-static.png"} }) {
            id
            fluid(toFormat: WEBP, maxWidth: 1280) {
                originalName
                srcWebp
            }
        }

        # contentfulContent(slug:{eq:"content:footer:title"}) {
        #     id
        #     slug
        #     title
        # }
    }
`;

interface IFooterProps {
    fill: boolean | string
}

class Footer extends React.Component<IFooterProps> {
    public render() {
        return (
            <StaticQuery query={siteQuery} render={(data) => {
                const {name} = data.allSite.edges[0].node.siteMetadata

                return (
                    <MainCover
                        media={{
                            url: undefined,
                            placeholder: data.imageSharp?.fluid?.srcWebp,
                            styles: {
                                background: `${this.props.fill.toString() == '#000' 
                                    ? '#FAFAFA' : 'black'}`,
                                transform: 'scale(1)',
                                width: '100%',
                                height: '100%',
                                objectFit: 'contain',
                                objectPosition: 'right bottom'
                            }
                        }}
                        angle={{
                            polygon: {
                                top: {
                                    width: '100%',
                                    angle: 85
                                },
                                bottom: {
                                    width: '100%',
                                    angle: 0
                                }
                            }
                        }}
                    >
                        <Section narrow
                                 style={{
                                     padding: '3em',
                                 }}
                        >
                            <FooterContainer
                                flexWrap={'wrap'}
                                style={{
                                    color: `${this.props.fill}`,
                                }}
                            >
                                <Box width={[1, 1 / 2]} p={[2]}
                                     textAlign={'left'}
                                >
                                    <FooterTitle>
                                        {/* {data.contentfulContent.title} */}
                                        {"Build Cloud Native"}
                                        <br />
                                        {"Build with Certainty"}
                                    </FooterTitle>
                                </Box>

                                <Box width={[1, 1 / 4]} p={[2, 3]} />

                                <Box width={[1, 1 / 4]} p={[2,3]}>
                                    <Link
                                        to={data.sitePlugin.pluginOptions.basePath}
                                        data-a11y="false"
                                        title="Navigate back to the homepage"
                                        aria-label="Navigate back to the homepage"
                                    >
                                        <DefaultLogo
                                            fill={this.props.fill.toString()}
                                        />
                                    </Link>
                                </Box>
                            </FooterContainer>

                            <HorizontalRule/>

                            <FooterContainer
                                flexWrap={'wrap'}
                                style={{
                                    textAlign: 'left',
                                    opacity: 0.33,
                                    color: this.props.fill
                                }}
                            >
                                <Box width={[1, 1 / 3]}>
                                    <FooterText>
                                        © {name} All Rights Reserved
                                    </FooterText>
                                </Box>
                                <Box width={[0, 1 / 3]} />
                                <Box width={[1, 1 / 3]}
                                     textAlign={'left'}
                                >
                                    <FooterText>
                                        <UserCheck width={16}/>
                                        <Link to={AppRegistry.Contact.Email.Parameters}>
                                            {'Write for us'}
                                        </Link>
                                        <Link to={'terms-of-service'}>
                                            {'Legal'}
                                        </Link>
                                    </FooterText>
                                </Box>
                            </FooterContainer>
                        </Section>
                    </MainCover>
                )
            }}/>
        )
    }
}

const StateWrapper = component => props => {
    const colorMode = useColorMode();
    const isDark = colorMode[0] === 'dark';
    const fill = isDark ? "#fff" : "#000";

    return <Footer {...props} fill={fill}/>
};

export default StateWrapper(Footer);

const FooterContainer = styled(Flex)`
    text-align: center;
    align-items: center;
    margin: 3em auto;
`

const HorizontalRule = styled(Box)`
  border-bottom: 1px dashed ${p => p.theme.colors.horizontalRule};
`

const FooterText = styled(Flex)`
  color: ${p => p.theme.colors.secondary};

  a {
    color: ${p => p.theme.colors.accent};
  }

  * {
    margin: 0 1em;
    display: inline;
  }
`

const FooterTitle = styled(Headings.h3)`
  vertical-align: bottom;
  color: dimgray;
  display: flex;
  text-align: left;
  font-family: ${p => p.theme.fonts.serif};
  font-weight: 500;
  font-size: 2em;
  font-style: italic;
  text-transform: uppercase;
`
